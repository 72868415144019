import { useEffect, useState } from "react";
import posthog from "posthog-js";

// eslint-disable-next-line react-refresh/only-export-components
export function cookieConsentGiven(): "yes" | "no" | "undecided" {
  if (!localStorage.getItem("cookie_consent")) {
    return "undecided";
  }
  return localStorage.getItem("cookie_consent") as "yes" | "no";
}

export function Banner() {
  const [consentGiven, setConsentGiven] = useState<
    "yes" | "no" | "undecided" | ""
  >("");

  useEffect(() => {
    setConsentGiven(cookieConsentGiven());
  }, []);

  useEffect(() => {
    if (consentGiven !== "") {
      posthog.set_config({
        persistence: consentGiven === "yes" ? "localStorage+cookie" : "memory",
      });
    }
  }, [consentGiven]);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookie_consent", "yes");
    setConsentGiven("yes");
  };

  const handleDeclineCookies = () => {
    localStorage.setItem("cookie_consent", "no");
    setConsentGiven("no");
  };

  if (consentGiven === "undecided") {
    return (
      <div className="fixed bottom-16 right-5 z-50">
        <div className="tui-window  max-w-md orange-168">
          <fieldset className="tui-fieldset tui-border-solid white-border center">
            <legend>Cookies.exe</legend>

            <div className="text-left">
              We use a single <a href={`https://posthog.com`}>Posthog</a>{" "}
              tracking cookie to understand how you use Rollup.wtf and help us
              improve. Please accept cookies so we can keep making Rollup.wtf
              better.
            </div>

            <br />

            <div className="tui-divider"></div>

            <br />

            <div className="flex gap-4">
              <button
                type="button"
                className="tui-button white-168"
                onClick={handleDeclineCookies}
              >
                Deny
              </button>

              <button
                type="button"
                className="tui-button white-168"
                onClick={handleAcceptCookies}
              >
                Accept
              </button>
            </div>
          </fieldset>
        </div>
      </div>
    );
  }

  return null;
}
